import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Accordion from "@ui/accordion";
import { IntroWrapper } from "./style";
const IntroAreaReverse = ({ data, index, layout, ...props }) => {
  if (!data?.items || data.items.length === 0) {
    return null;
  }
  const midpoint = Math.ceil(data.items.length / 2);
  const colFirstData = data.items.slice(0, midpoint);
  const colSecondData = data.items.slice(midpoint);
  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
          <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
          </Col>
        </Row>
        <Row>
          <Col lg={6}> 
              <Accordion data={colFirstData} layout={3} />
          </Col>
          <Col lg={6}> 
              <Accordion data={colSecondData} layout={3} />
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroAreaReverse.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3]),
};
IntroAreaReverse.defaultProps = {
  layout: 1
};
//};
export default IntroAreaReverse;
