import styled, { css, themeGet, device } from "@styled";

export const IntroWrapper = styled.section`
  ${(props) =>
    props.layout === 1 &&
    css`
      padding: 60px 0 100px;
      ${device.medium} {
        padding: 100px 0 150px;
      }
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding: 60px 0 100px;
      ${device.medium} {
        padding: 100px 0 100px;
      }
      background: linear-gradient(320deg, rgb(21, 5, 79), rgb(86, 23, 131));
      .section-title {
        p {
          color: #fff;
        }
      }
      
    `}

    ${(props) =>
      props.layout === 3 &&
      css`
        padding: 60px 0 30px;
        ${device.medium} {
          padding: 100px 0 50px;
        }
      `}
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
`;
export const Styledtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${themeGet("colors.primary")};
`;
export const RightBox = styled.div`
  margin: 15px 0;
  ${device.large} {
    margin: 0;
  }
`;
